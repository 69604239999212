img.up-down{
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
img.up-down{
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}